.productsWrapper {
  margin-bottom: 130px;
}

.productsTitle {
  font-size: 28px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #212121;
  margin: 0 0 78px 0;
}

.productsContainer {
  padding: 0 70px;
  margin-bottom: 85px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px 60px;
}

.productsDetailsButton {
  width: 300px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #212121;
  box-sizing: border-box;
  text-decoration: none !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #212121 !important;
  margin: 0 auto;
  transition: all 0.3s ease-in-out;
}
.productsDetailsButton:hover {
  box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.25);
}
.productsDetailsButton > svg {
  margin-left: 19px;
}

@media screen and (max-width: 992px) {
  .productsContainer {
    grid-template-columns: 1fr;
    gap: 15px 30px;
    padding: 0 30px;
  }
}
