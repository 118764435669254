.bannerContainer {
  padding: 0 70px;
  margin-bottom: 100px;
  min-height: 500px;
}

.banner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.banner::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f67da1;
  opacity: 0.9;
  z-index: 0;
}

.banner > .bannerImg {
  object-fit: cover;
  width: auto;
  height: 100%;
}

.bannerContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
}

.bannerContent > .contentTitle {
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  text-transform: uppercase;
  color: #212121;
  margin: 0 0 16px 0;
}
.bannerContent > .contentText {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #763648;
  margin: 0 0 44px 0;
}
.bannerContent > .contentButton {
  width: 210px;
  height: 50px;
  background: #212121;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: 0;
  text-transform: uppercase;
  color: #ffffff !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  text-decoration: none !important;
  transition: all 0.3s ease-in-out;
}
.contentButton:hover {
  box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.25);
}
.contentButton > svg {
  margin-left: 17px;
}

@media screen and (max-width: 992px) {
  .bannerContainer {
    padding: 0 30px;
  }
}

@media screen and (max-width: 576px) {
  .bannerContainer {
    padding: 0;
  }
}
