@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: "Quicksand", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.Footer_footerWrapper__1DjlF {
  display: flex;
  flex-direction: column;
}

.Footer_footer__BKhgK {
  min-height: 460px;
  background-color: #212121;
  color: #ffffff;
  padding: 41px 50px 12px 50px;
}

.Footer_footerMenu__3FjcG {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 42px;
}

.Footer_footerMenuItem__1zTvc {
  position: relative;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-decoration: none !important;
  color: #ffffff !important;
  margin-bottom: 14px;
}

.Footer_footerMenuItem__1zTvc:not(:first-child) {
  border-left: 1px solid #5c5c5c;
}

.Footer_footerMenuItem__1zTvc:not(:last-child) {
  padding: 0 20px 0 19px;
}

.Footer_footerMenuItem__1zTvc:first-child {
  padding: 0 20px 0 0;
}

.Footer_footerMenuItem__1zTvc:last-child {
  padding: 0 0 0 19px;
}

.Footer_footerMenuItem__1zTvc::after {
  content: "";
  position: absolute;
  bottom: -5px;
  height: 1px;
  background-image: linear-gradient(90deg, #ffffff 0%, #ffffff 100%);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 0% 1px;
  transition: all 0.3s ease-in-out;
}

.Footer_footerMenuItem__1zTvc:hover::after {
  background-size: 100% 1px;
}

.Footer_footerMenuItem__1zTvc:not(:last-child)::after {
  right: 20px;
  left: 19px;
}

.Footer_footerMenuItem__1zTvc:first-child::after {
  right: 20px;
  left: 0;
}

.Footer_footerMenuItem__1zTvc:last-child::after {
  right: 0;
  left: 19px;
}

.Footer_footerContactItems__3W3lN {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.Footer_footerContactItems__3W3lN > a {
  text-decoration: none !important;
  color: #ffffff !important;
}

.Footer_footerContactItem__12wsS {
  position: relative;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #a6a6a6;
  margin-bottom: 14px;
}

.Footer_footerContactItem__12wsS:not(:first-child) {
  border-left: 1px solid #5c5c5c;
}

.Footer_footerContactItem__12wsS:not(:last-child) {
  padding: 0 20px 0 19px;
}

.Footer_footerContactItem__12wsS:first-child {
  padding: 0 20px 0 0;
}

.Footer_footerContactItem__12wsS:last-child {
  padding: 0 0 0 19px;
}

a.Footer_footerContactItem__12wsS::after {
  content: "";
  position: absolute;
  bottom: -5px;
  height: 1px;
  background-image: linear-gradient(90deg, #ffffff 0%, #ffffff 100%);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 0% 1px;
  transition: all 0.3s ease-in-out;
}

a.Footer_footerContactItem__12wsS:hover::after {
  background-size: 100% 1px;
}

a.Footer_footerContactItem__12wsS:not(:last-child)::after {
  right: 20px;
  left: 19px;
}

a.Footer_footerContactItem__12wsS:first-child::after {
  right: 20px;
  left: 0;
}

a.Footer_footerContactItem__12wsS:last-child::after {
  right: 0;
  left: 19px;
}

.Footer_socialIcons__ZJXV7 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 41px;
}

.Footer_socialIcons__ZJXV7 > a:not(:last-child) {
  margin: 0 30px 0 0;
}

.Footer_socialIcon__3jMru {
  cursor: pointer;
  width: 21px;
  height: auto;
  transition: all 0.3s ease-in-out;
}

.Footer_socialIcon__3jMru:hover {
  transform: scale(1.1);
}

.Footer_copyrightLine__1HqCU {
  margin: 0 0 28px 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #a6a6a6;
}

.Footer_footerSelectors__3zeCY {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 20px;
}

.Footer_footerText__fjmNm {
  margin: 0 auto;
  max-width: 1170px;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  text-align: center;
  color: #767676;
}

.Footer_partnerLogos__3lYte {
  min-height: 80px;
  padding: 0 50px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.Footer_partnerLogos__3lYte > img:not(:first-child):not(:last-child) {
  margin: 15px;
}
.Footer_partnerLogos__3lYte > img:first-child {
  margin: 15px 15px 15px 0;
}
.Footer_partnerLogos__3lYte > img:last-child {
  margin: 15px 0 15px 15px;
}

.Switcher_switcher__1x481 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0 25px;
}

.Switcher_switchItem__3x3K8 {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #a6a6a6;
  text-transform: uppercase;
  cursor: pointer;
  padding: 0 10px 0 10px;
  transition: all .3s ease-in-out;
}

.Switcher_switchItem__3x3K8:not(:first-child) {
  border-left: 1px solid #5c5c5c;
  padding: 0 10px 0 9px;
}

.Switcher_switchItem__3x3K8.Switcher_isSelected__3GE-n {
  color: #ffffff;
}

.Dropdown_dropdown__sBpGS {
  position: relative;
  width: 60px;
  height: 30px;
  border: 1px solid #5c5c5c;
  background-color: #212121;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.Dropdown_dropdownOption__2m-99 {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  padding: 7.5px 0 7.5px 12px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: all 0.3s ease-in-out;
}

.Dropdown_dropdownArrow__1qerE {
  padding-right: 12px;
  width: 9px;
  height: auto;
}

.Dropdown_dropdownOptions__1fxRN {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translate(0%, 100%);
  border: 1px solid #5c5c5c;
  transition: all 0.3s ease-in-out;
  visibility: hidden;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  background-color: #212121;
  transition: all 0.3s ease-in-out;
}

.Dropdown_dropdownOptions__1fxRN.Dropdown_isOpened__1l4mg {
  visibility: visible;
  opacity: 1;
  max-height: 100px;
}

.Dropdown_dropdownOptions__1fxRN > .Dropdown_dropdownOption__2m-99 {
  width: 100%;
  padding: 7.5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Dropdown_dropdownOptions__1fxRN > .Dropdown_dropdownOption__2m-99:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.Header_headerWrapper__E6XIx {
  display: flex;
  flex-direction: column;
}

.Header_drawerOverlay__2WbEN {
  position: fixed;
  top: 145px;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
  z-index: 998;
}

.Header_header__1MOL- {
  min-height: 130px;
  background: #212121;
  padding: 30px 50px 0 50px;
  color: #ffffff;

  display: grid;
  /**/
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-auto-flow: row;
  /**/
  grid-template-columns: auto 1fr auto;
  grid-template-rows: -webkit-min-content 1fr;
  grid-template-rows: min-content 1fr;
  grid-gap: 15px 15px;
  gap: 15px 15px;
  grid-template-areas:
    "Infos Logo Actions"
    "Menu Menu Menu";

  transition: all 0.3s ease-in-out;
}

.Header_infos__1-Jpa {
  grid-area: Infos;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.Header_infoTel__5Oeve {
  color: #a6a6a6;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin-right: 37px;
  cursor: pointer;
}

.Header_infoLinks__1Ey_h {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.Header_link__2bYEV {
  position: relative;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  cursor: pointer;
  text-decoration: none !important;
  color: #ffffff !important;
}

.Header_link__2bYEV:first-child {
  padding: 0 14px 0 0;
}

.Header_link__2bYEV:last-child {
  padding: 0 0 0 15px;
}

.Header_link__2bYEV:not(:first-child):not(:last-child) {
  padding: 0 14px 0 15px;
}

.Header_link__2bYEV:not(:last-child) {
  border-right: 1px solid #5c5c5c;
}

.Header_link__2bYEV::after {
  content: "";
  position: absolute;
  bottom: -5px;
  height: 1px;
  background-image: linear-gradient(90deg, #ffffff 0%, #ffffff 100%);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 0% 1px;
  transition: all 0.3s ease-in-out;
}

.Header_link__2bYEV:first-child::after {
  left: 0;
  right: 14px;
}

.Header_link__2bYEV:last-child::after {
  left: 15px;
  right: 0;
}

.Header_link__2bYEV:not(:first-child):not(:last-child)::after {
  left: 15px;
  right: 14px;
}

.Header_link__2bYEV:hover::after {
  background-size: 100% 1px;
}

.Header_linkIcon__21h1W {
  width: 9px;
  height: auto;
  margin: 0 5px;
}

.Header_logo__3EvUy {
  grid-area: Logo;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Header_actions__1MEQL {
  grid-area: Actions;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.Header_actionIcons__1auFO {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: row;
}

.Header_actionIcon__zbCJB {
  margin: 0 25px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.Header_actionIcon__zbCJB:hover {
  transform: scale(1.1);
}

.Header_menu__2fg7G {
  grid-area: Menu;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
}

.Header_drawerIcon__17wO_ {
  grid-area: DrawerIcon;
  display: none;
  position: relative;
  width: 30px;
  background-image: linear-gradient(90deg, #ffffff 0%, #ffffff 100%);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 2px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.Header_drawerIcon__17wO_::before,
.Header_drawerIcon__17wO_::after {
  position: absolute;
  content: "";
  top: 50%;
  right: 0;
  left: 0;
  height: 2px;
  background-color: #ffffff;
  transform-origin: center;
  transition: all 0.3s ease-in-out;
}

.Header_drawerIcon__17wO_::before {
  transform: translate(0%, -10px);
}

.Header_drawerIcon__17wO_::after {
  transform: translate(0%, 10px);
}

.Header_drawerIcon__17wO_.Header_isClicked__2DxMn {
  background-size: 0% 3px;
}

.Header_drawerIcon__17wO_.Header_isClicked__2DxMn::before {
  transform: rotate(45deg);
}

.Header_drawerIcon__17wO_.Header_isClicked__2DxMn::after {
  transform: rotate(-45deg);
}

@media screen and (max-width: 1240px) {
  .Header_header__1MOL- {
    padding: 15px 25px 0 25px;
    grid-gap: 10px 10px;
    gap: 10px 10px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: -webkit-min-content -webkit-min-content 1fr;
    grid-template-rows: min-content min-content 1fr;
    grid-template-areas:
      "Infos Actions"
      "Logo Logo"
      "Menu Menu";
  }

  .Header_infos__1-Jpa {
    align-items: flex-end;
  }
}

@media screen and (min-width: 993px) {
  .Header_drawerOverlay__2WbEN {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .Header_header__1MOL- {
    height: 115px;
    grid-template-columns: 1fr -webkit-min-content;
    grid-template-columns: 1fr min-content;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      "Logo DrawerIcon"
      "Actions Actions";
  }

  .Header_menu__2fg7G {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    right: 0;
    bottom: 0;
    top: 145px;
    width: 75%;
    max-width: 350px;
    background-color: #212121;
    padding: 20px;
    transform: translateX(100%);
    z-index: 999;
    transition: all 0.3s ease-in-out;
  }

  .Header_menu__2fg7G.Header_isOpened__110xf {
    transform: translateX(0%);
  }

  .Header_infos__1-Jpa {
    display: none;
  }

  .Header_actions__1MEQL {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .Header_actionIcon__zbCJB {
    margin: 0 15px;
  }

  .Header_drawerIcon__17wO_ {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 576px) {
  .Header_actionIcon__zbCJB {
    margin: 0 10px;
  }
}

.Header_subHeader__2vGRQ {
  height: 60px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.Header_subHeader__2vGRQ > svg {
  height: 26px;
  width: auto;
  margin: 0 20px;
}

.Header_subHeader__2vGRQ > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #f67da1;
}

.Navigation_navItem__HbaxQ {
  position: relative;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin: 0 15px 16px 15px;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none !important;
  color: #ffffff !important;
}

.Navigation_navItem__HbaxQ::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  height: 1px;
  background-image: linear-gradient(90deg, #ffffff 0%, #ffffff 100%);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 0% 1px;
  transition: all 0.3s ease-in-out;
}

.Navigation_navItem__HbaxQ:hover::after {
  background-size: 100% 1px;
}

.Searchbar_searchbar__2_vbX {
  position: relative;
  width: 240px;
  padding: 9px 5px;
  margin-right: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-image: linear-gradient(90deg, #ffffff 0%, #ffffff 100%);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100% 1px;
}

.Searchbar_searchbar__2_vbX::before {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  content: "";
  background-image: linear-gradient(90deg, #5c5c5c 0%, #5c5c5c 100%);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100% 1px;
  transition: all 0.3s ease-in-out;
}

.Searchbar_searchbar__2_vbX:hover:before,
.Searchbar_searchbar__2_vbX:focus-within:before {
  background-size: 0% 1px;
}

.Searchbar_searchbar__2_vbX > input {
  width: calc(100% - 20px);
  padding-right: 5px;
  background: transparent;
  outline: none !important;
  color: #ffffff;
  border: none !important;
}

.Searchbar_searchbar__2_vbX > svg {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.Searchbar_searchbar__2_vbX > svg:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 992px) {
  .Searchbar_searchbar__2_vbX {
    margin-right: 0;
    margin-left: 25px;
  }
}

@media screen and (max-width: 576px) {
  .Searchbar_searchbar__2_vbX {
    max-width: 240px;
    width: auto;
  }
}

.Logo_logoText__3BCvz {
  font-weight: 400;
  font-size: 36px;
  line-height: 45px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none !important;
  color: #ffffff !important;
}

.Logo_logoText__3BCvz > span {
  color: #f67da1 !important;
}

.Carousel_embla__2R7NR {
  position: relative;
  margin-bottom: 70px;
}

.Carousel_emblaButtonPrev__2zYBo,
.Carousel_emblaButtonNext__1ECUl {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.Carousel_emblaButtonPrev__2zYBo {
  left: 20px;
}

.Carousel_emblaButtonNext__1ECUl {
  right: 20px;
}

.Carousel_emblaDots__zkVsF {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.Carousel_emblaViewport__1R7NR {
  overflow: hidden;
}
.Carousel_emblaContainer__1dr41 {
  display: flex;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.Carousel_emblaSlide__2gisk {
  position: relative;
  flex: 0 0 100%;
  height: 700px;
  min-width: 100%;
  margin-left: 10px;
  background: #fee1e6;
  overflow: hidden;
}

.Carousel_emblaSlideParallax__JbqMn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.Carousel_slideCover__2Y7v5 {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.Carousel_slideCover__2Y7v5::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 250px;
  z-index: 1;
  background: linear-gradient(270deg, rgba(254, 223, 233, 0) 0%, #fedfe9 100%);
}

.Carousel_slideCover__2Y7v5 > img {
  height: 100%;
  width: auto;
}

.Carousel_slideLabels__3miLE {
  position: absolute;
  top: 50%;
  left: 180px;
  max-width: 580px;
  transform: translate(0%, -50%);
  z-index: 2;
}

.Carousel_labelsTitle__1LNWH {
  font-weight: bold;
  font-size: 130px;
  line-height: 130px;
  margin: 0 0 17px 0;
  text-transform: uppercase;
  color: #212121;
}

.Carousel_labelsText__3aC9k {
  font-size: 24px;
  line-height: 34px;
  margin: 0 0 32px 8px;
  color: #ba8b88;
}

.Carousel_labelsButton__17J5X {
  height: 50px;
  width: 210px;
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #212121;
  text-decoration: none !important;
  color: #ffffff !important;
  transition: all 0.3s ease-in-out;
}
.Carousel_labelsButton__17J5X:hover {
  box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.25);
}

.Carousel_labelsButton__17J5X > svg {
  margin-left: 17px;
}

@media screen and (max-width: 992px) {
  .Carousel_emblaSlide__2gisk {
    height: 600px;
  }
  .Carousel_slideCover__2Y7v5 {
    right: -50%;
  }
  .Carousel_slideLabels__3miLE {
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .Carousel_labelsTitle__1LNWH {
    font-size: 100px;
    line-height: 100px;
  }
  .Carousel_labelsText__3aC9k {
    font-size: 20px;
    line-height: 28px;
  }
}

@media screen and (max-width: 576px) {
  .Carousel_emblaSlide__2gisk {
    height: 500px;
  }
  .Carousel_labelsTitle__1LNWH {
    font-size: 70px;
    line-height: 70px;
  }
  .Carousel_labelsText__3aC9k {
    font-size: 16px;
    line-height: 24px;
  }
}

.Dots_dotsConteiner__PGa5I {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.Dots_dotsConteiner__PGa5I > .Dots_dot__3i1L_ {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 15px;
  cursor: pointer;
  background-color: #212121;
  opacity: 0.2;
  transition: all 0.3s ease-in-out;
}

.Dots_dot__3i1L_.Dots_selected__1lQxg {
  opacity: 1;
}

.Products_productsWrapper__Op5cs {
  margin-bottom: 130px;
}

.Products_productsTitle__mbGz- {
  font-size: 28px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #212121;
  margin: 0 0 78px 0;
}

.Products_productsContainer__3QNwf {
  padding: 0 70px;
  margin-bottom: 85px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 30px 60px;
  gap: 30px 60px;
}

.Products_productsDetailsButton__2lvya {
  width: 300px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #212121;
  box-sizing: border-box;
  text-decoration: none !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #212121 !important;
  margin: 0 auto;
  transition: all 0.3s ease-in-out;
}
.Products_productsDetailsButton__2lvya:hover {
  box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.25);
}
.Products_productsDetailsButton__2lvya > svg {
  margin-left: 19px;
}

@media screen and (max-width: 992px) {
  .Products_productsContainer__3QNwf {
    grid-template-columns: 1fr;
    grid-gap: 15px 30px;
    gap: 15px 30px;
    padding: 0 30px;
  }
}

.ProductCard_productCard__1QAJb {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr -webkit-min-content;
  grid-template-rows: 1fr min-content;
  grid-gap: 0px 0px;
  gap: 0px 0px;
  grid-template-areas:
    "Header"
    "Content";
}

.ProductCard_productHeader__1aT9H {
  grid-area: Header;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.ProductCard_headerImg__p0d5q {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.ProductCard_headerIcon__Hedym {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background: #f4f0f1;
  cursor: pointer;
  -webkit-clip-path: polygon(0 0, 100% 100%, 100% 0);
          clip-path: polygon(0 0, 100% 100%, 100% 0);
}
.ProductCard_headerIcon__Hedym:hover > svg {
  transform: scale(1.1);
}
.ProductCard_headerIcon__Hedym > svg {
  margin: 7px;
  height: 16px;
  width: auto;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.ProductCard_productContent__BD0dk {
  grid-area: Content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ProductCard_contentTitle__3lDTf {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #212121;
  margin: 30px 0 13px 0;
}

.ProductCard_contentDescription__MhROz {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #a2a2a2;
  margin: 0 0 19px 0;
}

.ProductCard_contentPrices__2P0mH {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.ProductCard_contentPrices__2P0mH > .ProductCard_salePrice__3hUMN {
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  color: #212121;
  margin: 0 10px;
}

.ProductCard_contentPrices__2P0mH > .ProductCard_normalPrice__26yDr {
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  -webkit-text-decoration-line: line-through;
          text-decoration-line: line-through;
  color: #a1a1a1;
  margin: 0 10px;
}

.ProductCard_contentButton__3d4fb {
  width: 160px;
  height: 50px;
  background: #212121;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #ffffff !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none !important;
  transition: all 0.3s ease-in-out;
}
.ProductCard_contentButton__3d4fb:hover {
  box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 992px) {
  .ProductCard_productCard__1QAJb {
    grid-template-columns: minmax(200px, 300px) minmax(200px, 1fr);
    grid-template-rows: 1fr;
    grid-gap: 0px 10px;
    gap: 0px 10px;
    grid-template-areas: "Header Content";
  }
}

@media screen and (max-width: 576px) {
  .ProductCard_productCard__1QAJb {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr -webkit-min-content;
    grid-template-rows: 1fr min-content;
    grid-gap: 0px 0px;
    gap: 0px 0px;
    grid-template-areas:
      "Header"
      "Content";
  }
}

.Rating_ratingContainer__2vOg8 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 30px;
}

.Rating_ratingContainer__2vOg8 > svg:not(:first-child) {
  margin-left: 10px;
  cursor: pointer;
}

.Rating_ratingContainer__2vOg8 > span {
  font-weight: 500;
  font-size: 12px;
  line-height: 28px;
  text-align: center;
  color: #a2a2a2;
  margin: 0 0 0 13px;
}

.Mosaic_mosaicContainer__12Zbt {
  padding: 0 70px 0 70px;
  margin-bottom: 146px;
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-rows: minmax(400px, 1fr);
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: 20px 20px;
  gap: 20px 20px;
}

.Mosaic_mosaicElement__2z520 {
  position: relative;
}

.Mosaic_mosaicElement__2z520:first-child,
.Mosaic_mosaicElement__2z520:last-child {
  grid-column: span 2;
}

.Mosaic_mosaicElement__2z520:not(:first-child):not(:last-child) {
  grid-column: span 1;
}

.Mosaic_mosaicElement__2z520 > .Mosaic_elementImg__1oX2B {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.Mosaic_mosaicElement__2z520 > .Mosaic_elementTitle__3W9ks {
  font-weight: 500;
  font-size: 38px;
  line-height: 56px;
  text-transform: uppercase;
  color: #212121;
  position: absolute;
  top: 47px;
  left: 40px;
}

.Mosaic_mosaicElement__2z520:nth-child(2) > .Mosaic_elementTitle__3W9ks,
.Mosaic_mosaicElement__2z520:nth-child(5) > .Mosaic_elementTitle__3W9ks {
  color: #ffffff;
}

@media screen and (max-width: 992px) {
  .Mosaic_mosaicContainer__12Zbt {
    padding: 0 30px;
    grid-template-rows: minmax(300px, 1fr);
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

@media screen and (max-width: 768px) {
  .Mosaic_mosaicElement__2z520:first-child,
  .Mosaic_mosaicElement__2z520:last-child {
    grid-column: span 1;
  }
}

.Banner_bannerContainer__17Oz- {
  padding: 0 70px;
  margin-bottom: 100px;
  min-height: 500px;
}

.Banner_banner__2cHak {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.Banner_banner__2cHak::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f67da1;
  opacity: 0.9;
  z-index: 0;
}

.Banner_banner__2cHak > .Banner_bannerImg__21Klw {
  object-fit: cover;
  width: auto;
  height: 100%;
}

.Banner_bannerContent__a6iQp {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
}

.Banner_bannerContent__a6iQp > .Banner_contentTitle__1x5EN {
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  text-transform: uppercase;
  color: #212121;
  margin: 0 0 16px 0;
}
.Banner_bannerContent__a6iQp > .Banner_contentText__34wvO {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #763648;
  margin: 0 0 44px 0;
}
.Banner_bannerContent__a6iQp > .Banner_contentButton__32lGJ {
  width: 210px;
  height: 50px;
  background: #212121;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: 0;
  text-transform: uppercase;
  color: #ffffff !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  text-decoration: none !important;
  transition: all 0.3s ease-in-out;
}
.Banner_contentButton__32lGJ:hover {
  box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.25);
}
.Banner_contentButton__32lGJ > svg {
  margin-left: 17px;
}

@media screen and (max-width: 992px) {
  .Banner_bannerContainer__17Oz- {
    padding: 0 30px;
  }
}

@media screen and (max-width: 576px) {
  .Banner_bannerContainer__17Oz- {
    padding: 0;
  }
}

