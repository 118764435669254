.searchbar {
  position: relative;
  width: 240px;
  padding: 9px 5px;
  margin-right: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-image: linear-gradient(90deg, #ffffff 0%, #ffffff 100%);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100% 1px;
}

.searchbar::before {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  content: "";
  background-image: linear-gradient(90deg, #5c5c5c 0%, #5c5c5c 100%);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100% 1px;
  transition: all 0.3s ease-in-out;
}

.searchbar:hover:before,
.searchbar:focus-within:before {
  background-size: 0% 1px;
}

.searchbar > input {
  width: calc(100% - 20px);
  padding-right: 5px;
  background: transparent;
  outline: none !important;
  color: #ffffff;
  border: none !important;
}

.searchbar > svg {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.searchbar > svg:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 992px) {
  .searchbar {
    margin-right: 0;
    margin-left: 25px;
  }
}

@media screen and (max-width: 576px) {
  .searchbar {
    max-width: 240px;
    width: auto;
  }
}
