.embla {
  position: relative;
  margin-bottom: 70px;
}

.emblaButtonPrev,
.emblaButtonNext {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.emblaButtonPrev {
  left: 20px;
}

.emblaButtonNext {
  right: 20px;
}

.emblaDots {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.emblaViewport {
  overflow: hidden;
}
.emblaContainer {
  display: flex;
  user-select: none;
}
.emblaSlide {
  position: relative;
  flex: 0 0 100%;
  height: 700px;
  min-width: 100%;
  margin-left: 10px;
  background: #fee1e6;
  overflow: hidden;
}

.emblaSlideParallax {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.slideCover {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.slideCover::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 250px;
  z-index: 1;
  background: linear-gradient(270deg, rgba(254, 223, 233, 0) 0%, #fedfe9 100%);
}

.slideCover > img {
  height: 100%;
  width: auto;
}

.slideLabels {
  position: absolute;
  top: 50%;
  left: 180px;
  max-width: 580px;
  transform: translate(0%, -50%);
  z-index: 2;
}

.labelsTitle {
  font-weight: bold;
  font-size: 130px;
  line-height: 130px;
  margin: 0 0 17px 0;
  text-transform: uppercase;
  color: #212121;
}

.labelsText {
  font-size: 24px;
  line-height: 34px;
  margin: 0 0 32px 8px;
  color: #ba8b88;
}

.labelsButton {
  height: 50px;
  width: 210px;
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #212121;
  text-decoration: none !important;
  color: #ffffff !important;
  transition: all 0.3s ease-in-out;
}
.labelsButton:hover {
  box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.25);
}

.labelsButton > svg {
  margin-left: 17px;
}

@media screen and (max-width: 992px) {
  .emblaSlide {
    height: 600px;
  }
  .slideCover {
    right: -50%;
  }
  .slideLabels {
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .labelsTitle {
    font-size: 100px;
    line-height: 100px;
  }
  .labelsText {
    font-size: 20px;
    line-height: 28px;
  }
}

@media screen and (max-width: 576px) {
  .emblaSlide {
    height: 500px;
  }
  .labelsTitle {
    font-size: 70px;
    line-height: 70px;
  }
  .labelsText {
    font-size: 16px;
    line-height: 24px;
  }
}
