.logoText {
  font-weight: 400;
  font-size: 36px;
  line-height: 45px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none !important;
  color: #ffffff !important;
}

.logoText > span {
  color: #f67da1 !important;
}
