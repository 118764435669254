.productCard {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr min-content;
  gap: 0px 0px;
  grid-template-areas:
    "Header"
    "Content";
}

.productHeader {
  grid-area: Header;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.headerImg {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.headerIcon {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background: #f4f0f1;
  cursor: pointer;
  clip-path: polygon(0 0, 100% 100%, 100% 0);
}
.headerIcon:hover > svg {
  transform: scale(1.1);
}
.headerIcon > svg {
  margin: 7px;
  height: 16px;
  width: auto;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.productContent {
  grid-area: Content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contentTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #212121;
  margin: 30px 0 13px 0;
}

.contentDescription {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #a2a2a2;
  margin: 0 0 19px 0;
}

.contentPrices {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.contentPrices > .salePrice {
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  color: #212121;
  margin: 0 10px;
}

.contentPrices > .normalPrice {
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  text-decoration-line: line-through;
  color: #a1a1a1;
  margin: 0 10px;
}

.contentButton {
  width: 160px;
  height: 50px;
  background: #212121;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #ffffff !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none !important;
  transition: all 0.3s ease-in-out;
}
.contentButton:hover {
  box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 992px) {
  .productCard {
    grid-template-columns: minmax(200px, 300px) minmax(200px, 1fr);
    grid-template-rows: 1fr;
    gap: 0px 10px;
    grid-template-areas: "Header Content";
  }
}

@media screen and (max-width: 576px) {
  .productCard {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr min-content;
    gap: 0px 0px;
    grid-template-areas:
      "Header"
      "Content";
  }
}
