.ratingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 30px;
}

.ratingContainer > svg:not(:first-child) {
  margin-left: 10px;
  cursor: pointer;
}

.ratingContainer > span {
  font-weight: 500;
  font-size: 12px;
  line-height: 28px;
  text-align: center;
  color: #a2a2a2;
  margin: 0 0 0 13px;
}
