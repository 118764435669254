.navItem {
  position: relative;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin: 0 15px 16px 15px;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none !important;
  color: #ffffff !important;
}

.navItem::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  height: 1px;
  background-image: linear-gradient(90deg, #ffffff 0%, #ffffff 100%);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 0% 1px;
  transition: all 0.3s ease-in-out;
}

.navItem:hover::after {
  background-size: 100% 1px;
}
